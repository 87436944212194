import React, { useEffect, useState, Fragment, MouseEventHandler, useRef } from 'react';
import { ContentfulNavigationSubNav } from 'types';
import { getLink } from 'routes';
import { getEntryAnchor } from 'routes/getEntryIdentifier';
import styled, { css } from 'styled-components';
import {
  anchor,
  branding,
  breadcrumbHeight,
  button,
  grey40,
  mediaQueries,
  navType,
  navTypeEmphasized,
  universalMargin,
} from 'styles';
import { Link } from 'gatsby';
import Icon, { IconTypes } from 'components/generic/Icon';
import { useClickOutside } from 'utils';

type SecondaryNavigationNestedLinksProps = {
  subNavigation: ContentfulNavigationSubNav['subNavigation'];
  parentIsClosed: boolean;
};

export function SecondaryNavigationNestedLinks({ subNavigation, parentIsClosed }: SecondaryNavigationNestedLinksProps) {
  const [isOpen, set] = useState(false);

  // overrid browser summary open state w/ react state:
  function clickHandler(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    set(currentOpen => !currentOpen);
  }

  useEffect(() => set(currentOpen => (parentIsClosed ? !parentIsClosed : currentOpen)), [parentIsClosed]);

  const detailsRef = useRef<HTMLDetailsElement>(null);

  useEffect(() => {
    const { current: dropdownElem } = detailsRef;
    if (!dropdownElem) return;
    dropdownElem.open = isOpen;
  }, [isOpen, detailsRef]);

  return (
    <Fragment>
      {isOpen ? <TranslucentBackground /> : null}
      <Details ref={detailsRef}>
        {/* clickHandler as unknown as MouseEventHandler<HTMLElement> - forces event type: */}
        <SummaryExpandTrigger onClick={clickHandler as unknown as MouseEventHandler<HTMLElement>}>
          Discover <ExpandedIndicator IconType={IconTypes.down} />
        </SummaryExpandTrigger>
        {isOpen ? <LinksDropdown closeCallback={set} subNavigation={subNavigation} /> : null}
      </Details>
    </Fragment>
  );
}

type LinksDropdownProps = {
  subNavigation: SecondaryNavigationNestedLinksProps['subNavigation'];
  closeCallback: React.Dispatch<React.SetStateAction<boolean>>;
};

function LinksDropdown({ subNavigation, closeCallback }: LinksDropdownProps) {
  const ref = useRef<HTMLUListElement>(null);

  // overrid browser summary open state w/ react state:
  function clickHandler(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    closeCallback(currentOpen => !currentOpen);
  }

  useClickOutside<HTMLUListElement>(clickHandler, {
    elementRef: ref,
    debug: { name: LinksDropdown.name, enable: false },
  });

  return (
    <LinksContainer>
      <Links as="ul" ref={ref}>
        {subNavigation.map(node => {
          const url = ('reference' in node && node.reference) || ('url' in node && node.url) ? getLink(node) : '';
          const anchor = node.block ? getEntryAnchor(node.block) : '';

          return (
            <LinkContainer key={node.id}>
              <StyledLink to={url + anchor} activeClassName="active" partiallyActive>
                {node.label}
              </StyledLink>
            </LinkContainer>
          );
        })}
      </Links>
    </LinksContainer>
  );
}

const TranslucentBackground = styled.div`
  position: fixed;
  top: ${breadcrumbHeight}px;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(23, 27, 31, 0.6);
`;

const Details = styled.details`
  display: inline-block;
  ${mediaQueries.large(css`
    position: relative;
  `)}
`;

const SummaryExpandTrigger = styled.summary`
  ${button}
  ${navType}
  list-style: none;
  ::-webkit-details-marker {
    display: none;
  }
  ${Details}[open] &,
  &:hover {
    color: ${branding.accentColor};
  }
`;

const ExpandedIndicator = styled(Icon)`
  padding-left: ${universalMargin / 2}px;
  & svg {
    transition: transform 300ms ease-in-out;
  }
  ${Details}[open] & svg {
    transform: rotate(180deg);
  }
`;

const LinksContainer = styled.div`
  z-index: 1;
  position: absolute;
  overflow: hidden;
  clip-path: border-box;
  width: 100%;
  left: 0;
  height: calc(100vh - var(--headerHeight));
  ${mediaQueries.large(css`
    left: -${universalMargin * 4}px; //adjust left text with button left by offsetting it with the links' box left-padding set below 👇
    width: 286px;
  `)}
`;

const Links = styled.div`
  position: fixed;
  max-height: calc(100vh - var(--headerHeight) + ${breadcrumbHeight - 28}px);
  background: ${branding.cardGrid.item.default.background};
  border-radius: 0 0 ${branding.boxBorderRadius} ${branding.boxBorderRadius};
  overflow: scroll;
  transition: transform 300ms ease-in-out;
  padding: ${universalMargin * 2}px ${universalMargin * 4}px ${universalMargin * 4}px; // links box padding set here 👈
  transform: translateY(-100%);
  ${Details}[open] & {
    transform: translateY(0);
  }
`;

const LinkContainer = styled.li`
  display: block !important;
  :not(:first-child) {
    padding-top: ${universalMargin * 2}px;
  }
  :not(:last-child) {
    padding-bottom: ${universalMargin * 2}px;
    border-bottom: 1px solid ${grey40};
  }
  ${mediaQueries.large(css`
    &&& {
      padding-top: 0;
      border-bottom: none;
    }
  `)}
`;

//? how to active style for anchor links?
const StyledLink = styled(Link)`
  ${anchor}
  ${navType}
  text-decoration: none;
  &.active {
    ${navTypeEmphasized}
  }
  &:hover {
    text-decoration: underline;
  }
`;
