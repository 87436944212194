import { useRef, useEffect } from 'react';

/**
 * trigger a callback after moved a distance of {distanceTriggerThreshold} px length
 * downward callback is called with `true`, upwards callback is called with `false`
 */
export function useCallbackOnDirectionalMovement(
  distanceTriggerThreshold = 100,
  callback: (setForward: (forward: boolean) => boolean) => void
) {
  const prevMoveDistance = useRef<number>(0);
  const prevDirection = useRef<number>(0);
  const prevDirectionChangePos = useRef<number>(0);

  useEffect(() => {
    function onScroll(event: Event) {
      const { current: lastDirection } = prevDirection;
      const { current: lastDirectionChangePos } = prevDirectionChangePos;
      const direction = window.scrollY - prevMoveDistance.current;
      const directionChange = signNumber(direction) !== signNumber(lastDirection);
      if (directionChange) prevDirectionChangePos.current = window.scrollY;
      const distanceMoved = lastDirectionChangePos - window.scrollY;
      callback(curIsOpen => {
        switch (true) {
          case distanceMoved < -distanceTriggerThreshold:
            return true;
          case distanceMoved > distanceTriggerThreshold:
            return false;
          default:
            return curIsOpen;
        }
      });
      prevDirection.current = direction;
      prevMoveDistance.current = window.scrollY;
      return void event;
    }
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  });
}

/** Returns the sign of the x, indicating whether x is positive, negative or zero.  */
function signNumber(x: number) {
  function sign(x: number) {
    // eslint-disable-next-line no-self-compare
    return (x = +x) === 0 || x !== x ? x : x < 0 ? -1 : 1; // extracted from core-js polyfill https://github.com/zloirock/core-js/blob/b6432fbb749c76d6d4ab0cb8dc2d4f9351a86470/packages/core-js/internals/math-sign.js#L6
  }
  if (typeof Math.sign !== 'function') return sign(x);
  return Math.sign(x);
}
