import {
  ContentfulNavigation,
  isContentfulNavigationLink,
  isContentfulNavigationReference,
  isContentfulNavigationSubNav,
} from 'types';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  anchor,
  branding,
  breakpoints,
  gridPaddings,
  maxLayoutWidth,
  mediaQueries,
  mediumBody,
  universalMargin,
  webexHeaderHeight,
  white,
} from 'styles';
import { getLink } from 'routes';
import { getEntryAnchor } from 'routes/getEntryIdentifier';
import { SecondaryNavigationNestedLinks } from './SecondaryNavigationDropdown';
import { Link } from 'gatsby';
import Section from 'components/generic/Section';
import { useCallbackOnDirectionalMovement } from './useCallbackOnDirectionalMovement';
import { isBuildTargetWebex } from 'white-label';

type SecondaryNavigationProps = {
  currentPageId: string;
  menu: ContentfulNavigation;
};

export function SecondaryNavigation({ currentPageId, menu }: SecondaryNavigationProps) {
  const treeMap = flattenNavigationTree(currentPageId, [menu]);
  const currentNode = treeMap?.[treeMap.length - 2] || menu;
  const [isClosed, set] = useState(false);
  useCallbackOnDirectionalMovement(!isBuildTargetWebex() ? 100 : 1, set);
  const topLevel = treeMap?.length === 1;
  const secondaryNav = (
    <Container size="max" webexStyles={isBuildTargetWebex()} hidden={isClosed} innerPadding>
      <Links>
        {treeMap
          ? treeMap.map((node, index) => {
              const url = getLink(node);
              const anchor = node.block ? getEntryAnchor(node.block) : '';

              if (index === 0) {
                return (
                  <LinkContainer key={node.id}>
                    {topLevel ? (
                      <StyledSpan>{node.label}</StyledSpan>
                    ) : (
                      <StyledLink to={url + anchor}>{node.label}</StyledLink>
                    )}
                  </LinkContainer>
                );
              }

              return (
                <LinkContainer key={node.id}>
                  <StyledSpanLargeOnly>{node.label}</StyledSpanLargeOnly>
                </LinkContainer>
              );
            })
          : null}
      </Links>
      {isContentfulNavigationSubNav(currentNode) ? (
        <SecondaryNavigationNestedLinks subNavigation={currentNode.subNavigation} parentIsClosed={isClosed} />
      ) : null}
    </Container>
  );

  return (
    <>
      <FixedContainer>{secondaryNav}</FixedContainer>
      <FlowingContainer>{secondaryNav}</FlowingContainer>
    </>
  );
}

const Container = styled(Section)<{ webexStyles: boolean; hidden: boolean }>`
  background: ${branding.cardGrid.item.default.background};
  transform: translate(0, 0);
  transition: transform 0.4s;
  max-width: ${maxLayoutWidth}px;
  margin: 0 auto;
  .inner {
    display: flex;
    padding: ${universalMargin * 3}px 0;
    ${gridPaddings}
    flex-direction: row;
    > *:nth-last-child(1) {
      align-self: flex-end;
      margin-left: auto;
    }
    ${mediaQueries.large(css`
      display: block;
    `)}
  }
  ${props =>
    props.hidden
      ? css`
          transform: translate(0, -100%);
        `
      : css``}
  ${props =>
    props.webexStyles
      ? css`
          background-color: black;
          color: ${white};
          transition: transform 0.3s linear;
          ${props.hidden ? `transform: translate(0, -${webexHeaderHeight - 10}px) translate(0, -100%);` : ``}
          &::before {
            /* here 👇 we add some background to the top of the nav bar to fix flicker between hardware.webex header and this nav */
            content: '';
            position: absolute;
            width: 100%;
            background: inherit;
            height: 50px;
            top: -50px;
          }
          // Breakpoint from webex navigation
          @media (max-width: 1024px) {
            display: none;
          }
        `
      : css``}
`;

const FlowingContainer = styled.div`
  @media screen {
    visibility: hidden;
  }
`;

const FixedContainer = styled.div`
  position: fixed;
  z-index: 200;
  width: 100%;
  @media print {
    visibility: hidden;
  }
`;

const Links = styled.ul`
  display: inline-block;
  ${mediaQueries.large(css`
    padding-right: ${universalMargin * 8}px;
  `)}
`;

const LinkContainer = styled.li`
  display: inline;
  @media screen and (max-width: ${breakpoints.large - 1}px) {
    :nth-last-child(n + 3) {
      display: none;
    }
  }
  @media screen and (min-width: ${breakpoints.small}px) and (max-width: ${breakpoints.large - 1}px) {
    :nth-last-child(2)::after {
      content: ' / ';
    }
  }
  ${mediaQueries.large(css`
    :nth-last-child(n + 4) {
      display: none;
    }
    :nth-last-child(n + 2)::after {
      content: ' / ';
    }
  `)}
`;

const StyledSpan = styled.span`
  ${mediumBody}
  font-weight: 700;
`;

const StyledSpanLargeOnly = styled.span`
  display: none;
  text-decoration: none;
  ${mediumBody}
  ${LinkContainer}:last-child & {
    font-weight: 700;
  }
  @media screen and (min-width: ${breakpoints.small}px) {
    display: inline-block;
  }
`;

const StyledLink = styled(Link)`
  ${anchor}
  text-decoration: none;
  ${mediumBody}
  ${LinkContainer}:last-child & {
    font-weight: 700;
  }
  @media screen and (max-width: ${breakpoints.small - 1}px) {
    ${LinkContainer}:nth-last-child(2) & {
      font-weight: 700;
      ::before {
        content: '';
        padding-right: 6px;
        display: inline-block;
        width: 12px;
        height: 12px;
        transform: rotate(180deg);
        background-position: right; // right-to-left due to rotate(180deg)!
        background-size: 12px;
        background-repeat: no-repeat;
        background-image: url(/images/link_icon_entry.svg);
      }
    }
  }

  @media screen and (min-width: ${breakpoints.small}px) and (max-width: ${breakpoints.large - 1}px) {
    ${LinkContainer}:nth-last-child(3) & {
      font-size: 0; //hack hide content but let ::after still function as clickable link
      ::after {
        content: '...';
        font-size: 18px;
      }
    }
  }

  ${mediaQueries.large(css`
    ${LinkContainer}:nth-last-child(3) & {
      font-size: 0; //hack hide content but let ::after still function as clickable link
      ::after {
        content: '...';
        font-size: 18px;
      }
    }
  `)}
`;

// Takes the current page ID, a ContentfulNavigation tree structure and
// returns an flattened array of Navigation nodes describing a complete
// breadcrumb from the pages sub navigation node.
function flattenNavigationTree(
  currentPageId: string,
  tree: Array<ContentfulNavigation>,
  chain: Array<ContentfulNavigation> = []
): Array<ContentfulNavigation> | undefined {
  for (const node of tree) {
    if (isContentfulNavigationReference(node) && node.reference?.id === currentPageId) {
      return [...chain, node];
    }
    if (isContentfulNavigationSubNav(node)) {
      const result = flattenNavigationTree(currentPageId, node.subNavigation, [...chain, node]);
      if (result) {
        return result;
      }
    }
    if (isContentfulNavigationLink(node)) {
      return [...chain, node];
    }
  }
  return undefined;
}
