import { ContentfulPage, PageSections } from 'types';
import { PageProps, graphql } from 'gatsby';
import Head from 'components/Head';
import Page from 'components/Page';
import React from 'react';
import { SecondaryNavigation } from 'components/SecondaryNavigation';
import { SiteMetadata } from 'white-label/shared/types';

export type PageTemplateQueryProps = {
  home: ContentfulPage & PageSections;
  site: { siteMetadata: Pick<SiteMetadata, 'image'> };
};

export default function PageTemplate({ data, location }: PageProps<PageTemplateQueryProps, null>) {
  const title = data.home.heading;
  const description = data.home.plainDescription.plainDescription;
  const imageUrl = data.home.image?.image?.gatsbyImageData?.images?.fallback?.src || data.site.siteMetadata.image;
  const keywords = data.home.metaKeywords?.metaKeywords;

  return (
    <>
      <Head location={location} title={title} description={description} image={imageUrl} metaKeywords={keywords} />
      {data.home.inPageMenu && <SecondaryNavigation currentPageId={data.home.id} menu={data.home.inPageMenu} />}
      <Page sections={data.home.sections} />
    </>
  );
}

// Important!!!
// If you change this query, you must copy it to protectedPage.tsx
export const query = graphql`
  query ($id: String!) {
    home: contentfulPage(contentful_id: { eq: $id }) {
      ...HomeContent
    }
    site {
      siteMetadata {
        image
      }
    }
  }
`;
